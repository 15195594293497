import React from "react";
import map from "../../assets/map.png";
import footerElipses from "../../assets/footerElipses.png";
const Footer = () => {
	return (
		<div className="relative">
			<div className="absolute bottom-0 w-full">
				<img src={footerElipses} alt="elipse1" className="w-full" />
			</div>
			<div
				className="content-wrapper py-40 text-white relative"
				id="contact"
			>
				<div className="text-4xl lg:text-6xl  lg:leading-relaxed	 max-w-screen-md">
					Get in touch with us
				</div>
				<div className="flex lg:flex-row flex-col lg:justify-between mt-16">
					<div className="flex lg:flex-row flex-col text-light">
						<div className="lg:max-w-md">
							<div className="font-bold">LOCATION</div>
							<div className="mt-4 text-xl">
								201 Shamrock, SK Bole Road, Near Dr. Antonio Da
								Silva High School, Dadar West, Mumbai,
								Maharashtra 400028.
							</div>
							<div className="font-bold mt-12">CONTACT</div>
							<div className="mt-4 text-xl">
								Brian Saldanha: +91 97695 94914 <br />{" "}
								brian@postbox28.com
							</div>
							<div className="font-bold mt-12">BUSINESS</div>
							<div className="mt-4 text-xl">
								contact@postbox28.com
							</div>
						</div>
						<div className="mt-12 lg:mt-0 lg:ml-16">
							<div className="font-bold">LINKS</div>
							<div className="flex flex-wrap lg:flex-col ">
								<a
									href="https://www.facebook.com/pobox28/"
									target="_blank"
									rel="noopener noreferrer"
									className="mt-4 text-xl hover:text-teal hover:underline transition-all"
								>
									Facebook
								</a>
								<a
									href="https://www.instagram.com/pobox28/"
									target="_blank"
									rel="noopener noreferrer"
									className="mt-4 text-xl ml-4 lg:ml-0 hover:text-teal hover:underline transition-all"
								>
									Instagram
								</a>

								<a
									href="https://wa.me/+919769594914"
									target="_blank"
									rel="noopener noreferrer"
									className="mt-4 text-xl ml-4 lg:ml-0 hover:text-teal hover:underline transition-all"
								>
									WhatsApp
								</a>
							</div>
						</div>
					</div>
					<a
						href="https://g.page/the-blip?share"
						target="_blank"
						rel="noopener noreferrer"
						className="mt-12 lg:mt-0 ml-12"
					>
						<img
							src={map}
							alt="map"
							className="lg:h-full lg:w-auto w-full"
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
