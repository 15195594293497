import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./FeaturedProjects.css";
import elipse2 from "../../assets/elipse2.png";
const FeaturedProjects = () => {
	const [data, setData] = useState({});
	useEffect(() => {
		const query = `{
      postbox28ProjectsCollection(where: {isFeatured: true}){
          items{
            name
            client
            description
            url
            addtionalFields
          }
      }
    }`;
		const fetchAPI = async () => {
			const response = await fetch(
				"https://graphql.contentful.com/content/v1/spaces/j09fj8t8jcuw/",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization:
							"Bearer kkenpDD079ww3vagsbAq9X7d9X5gTEmYGXcsDTtpP6k",
					},
					body: JSON.stringify({
						query: query,
					}),
				}
			);

			const data = await response.json();
			setData(data);
		};
		fetchAPI();
	}, []);

	return (
		<div className="relative overflow-hidden">
			<div className="absolute elipse2-container">
				<img
					src={elipse2}
					alt="elipse1"
					className="elipse1 w-3/4 lg:w-full"
				/>
			</div>
			<div className="content-wrapper text-white relative">
				<div className="text-3xl lg:text-4xl ">Featured Projects</div>
				<div className="mt-8">
					{data.data?.postbox28ProjectsCollection?.items.map(
						(item, index) => (
							<div className="grid grid-cols-12 gap-4 mt-12">
								<div
									className={`lg:col-span-7 player col-span-12 ${
										index % 2 !== 0
											? "lg:col-start-6 lg:order-last"
											: ""
									}`}
								>
									<ReactPlayer
										width="100%"
										height="100%"
										playing={true}
										muted={true}
										loop={true}
										url={item.url}
									/>
								</div>
								<div
									className={`lg:col-span-4  col-span-12 h-full flex flex-col justify-center ${
										index % 2 !== 0
											? "lg:order-1 lg:col-start-0"
											: "lg:col-start-9"
									}`}
								>
									<div className="text-xs uppercase font-bold opacity-60">
										{item.client}
									</div>
									<div className="text-xl mt-6">
										{item.name}
									</div>
									<div className="text-base mt-4 text-light">
										{item.description}
									</div>
									<div className="text-base mt-4 text-light">
										{item?.addtionalFields?.map(field => (
											<div>
												{field.name}: {field.value}
											</div>
										))}
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default FeaturedProjects;
