import AboutUsSection from "../components/AboutUsSection/AboutUsSection";
import FeaturedProjects from "../components/FeaturedProjects/FeaturedProjects";
import Hero from "../components/Hero/Hero";
import SEO from "../components/SEO";
import ServicesSection from "../components/ServicesSection/ServicesSection";
import StatsSection from "../components/StatsSection/StatsSection";

const Home = () => {
	return (
		<>
			<SEO title="Home | Postbox 28" />
			<Hero />
			<StatsSection />
			<FeaturedProjects />
			<ServicesSection />
			<AboutUsSection />
		</>
	);
};

export default Home;
