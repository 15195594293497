import React from "react";
import "./hero.css";
import Amazon from "../../assets/Amazon.svg";
import Cadbury from "../../assets/Cadbury.svg";
import Disney from "../../assets/Disney.svg";
import imax from "../../assets/imax.svg";
import Levi from "../../assets/Levi.svg";
import Netflix from "../../assets/Netflix.svg";
import Oneplus from "../../assets/Oneplus.svg";
import Siemens from "../../assets/Siemens.svg";
import Tinder from "../../assets/Tinder.svg";
import Whatsapp from "../../assets/Whatsapp.svg";
import HeroVid from "../../assets/hero.mp4";
const imgArray = [
	Amazon,
	Cadbury,
	Disney,
	imax,
	Levi,
	Netflix,
	Oneplus,
	Siemens,
	Tinder,
];
const Hero = () => {
	return (
		<div className="hero relative flex flex-col justify-between text-white">
			<video autoPlay muted loop className="video">
				<source src={HeroVid} type="video/mp4" />
				<div className="text-white">Video not supported</div>
			</video>
			<div className="z-20 flex w-full flex-col mt-48 mb-12 content-wrapper">
				<div className="text-4xl lg:text-6xl lg:leading-relaxed	 max-w-screen-lg">
					From the big pitch to the bigger picture, our
					post-production studio makes radical ideas real.
				</div>
			</div>
			<div className="w-full flex lg:flex-row flex-col-reverse justify-between">
				<div className="z-20 bg-primary-black lg:w-1/2 w-full">
					<div className="xl:ml-24 mx-8 my-10">
						<div className="max-w-md border-b-4 text-xl border-teal pb-4">
							We’ve worked with some remarkable brands, you could
							be one of them!
						</div>

						<div className="hidden lg:flex flex-row lg:flex-wrap">
							{imgArray.map((img, index) => (
								<div className="mt-10 z-20 mr-12  flex align-middle">
									<img
										src={img}
										key={index}
										alt={index}
										className="md:w-28 w-24 max-w-none"
									/>
								</div>
							))}
							<div className="text-xl mt-10 flex align-middle">
								+ many more
							</div>
						</div>
						<marquee className="lg:hidden">
							<div className="flex flex-row">
								{imgArray.map((img, index) => (
									<div className="mt-10 z-20 mr-12  flex align-middle">
										<img
											src={img}
											key={index}
											alt={index}
											className="md:w-28 w-24 max-w-none"
										/>
									</div>
								))}
								<div className="text-xl mt-10 flex align-middle">
									+ many more
								</div>
							</div>
						</marquee>
					</div>
				</div>
			</div>
			<div className="z-20 fixed bottom-6 right-6">
				<div className="self-end mb-10">
					<a
						href="https://wa.me/+919769594914"
						target="_blank"
						rel="noopener noreferrer"
						className="text-white font-semibold text-sm whatsapp-button lg:px-6 px-4 py-6 rounded-full lg:rounded-none"
					>
						<img
							src={Whatsapp}
							className="inline lg:mr-4"
							alt="Whatsapp"
						/>
						<span className="hidden lg:inline">
							Text us on WhatsApp
						</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Hero;
