import React from "react";
import { NavLink as Link } from "react-router-dom";
import Logo from "../../assets/Logo.svg";
import "./header.css";
const Header = ({ openDrawer }) => {
	return (
		<header className="header fixed w-screen top-0 z-40">
			<div className="content-wrapper flex justify-between px-5 py-8 items-center flex-row">
				<Link exact to="/">
					<div>
						<img src={Logo} alt="logo" className="w-56" />
					</div>
				</Link>
				<nav className="md:ml-auto hidden font-semibold xl:block text-sm  flex-wrap items-center justify-center">
					<Link
						exact={true}
						to="/"
						className={({ isActive }) =>
							`header-link mr-8 text-white ${
								isActive ? "opacity-100" : "opacity-50"
							}  hover:opacity-100 transition-all duration-200`
						}
					>
						HOME
					</Link>
					<Link
						exact={true}
						to="/work"
						className={({ isActive }) =>
							`header-link mr-8 text-white ${
								isActive ? "opacity-100" : "opacity-50"
							}  hover:opacity-100 transition-all duration-200`
						}
					>
						WORK
					</Link>
					<Link
						exact={true}
						to="/about"
						className={({ isActive }) =>
							`header-link mr-8 text-white ${
								isActive ? "opacity-100" : "opacity-50"
							}  hover:opacity-100 transition-all duration-200`
						}
					>
						ABOUT
					</Link>
					<Link
						exact={true}
						to="/clients"
						className={({ isActive }) =>
							`header-link mr-8 text-white ${
								isActive ? "opacity-100" : "opacity-50"
							}  hover:opacity-100 transition-all duration-200`
						}
					>
						CLIENTS
					</Link>
					<a
						href="#contact"
						className={`header-link mr-8 text-white opacity-50
							 hover:opacity-100 transition-all duration-200`}
					>
						CONTACT
					</a>
				</nav>
				<button
					onClick={() => openDrawer()}
					className="focus:outline-none block ml-auto xl:hidden"
					aria-label="menu"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="white"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="feather feather-menu"
					>
						<line x1="3" y1="12" x2="21" y2="12"></line>
						<line x1="3" y1="6" x2="21" y2="6"></line>
						<line x1="3" y1="18" x2="21" y2="18"></line>
					</svg>
				</button>
			</div>
		</header>
	);
};

export default Header;
