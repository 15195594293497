import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
const About = () => {
	const colors = ["bg-teal-dark", "bg-custom-red-dark", "bg-dark"];
	const [data, setData] = useState([]);
	useEffect(() => {
		const query = `{
      postbox28AboutusCollection(order: [sys_firstPublishedAt_ASC]) {
          items {
            name
            designation
            description
						photo {
							url
						}
          }
      }
    }`;
		const fetchAPI = async () => {
			const response = await fetch(
				"https://graphql.contentful.com/content/v1/spaces/j09fj8t8jcuw/",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization:
							"Bearer kkenpDD079ww3vagsbAq9X7d9X5gTEmYGXcsDTtpP6k",
					},
					body: JSON.stringify({
						query: query,
					}),
				}
			);

			const data = await response.json();

			const rows = data.data?.postbox28AboutusCollection?.items.reduce(
				function (rows, key, index) {
					return (
						(index % 2 === 0
							? rows.push([key])
							: rows[rows.length - 1].push(key)) && rows
					);
				},
				[]
			);
			setData([...rows]);
		};
		fetchAPI();
	}, []);
	return (
		<div className="content-wrapper text-white">
			<SEO title="About us | Postbox 28" />
			<div className="text-4xl lg:text-6xl pt-40 lg:leading-relaxed	 max-w-screen-md">
				Meet our passionate team of youngsters
			</div>
			<div className="w-full">
				{data?.map((item, index) => (
					<div
						className={`flex flex-col lg:flex-row mt-24 ${
							index % 2 === 0 ? "justify-start" : "justify-end"
						}`}
					>
						{item.map((item1, index1) => (
							<div
								className={`relative lg:ml-36 mx-auto lg:mx-0 lg:w-1/3 ${
									index1 % 2 !== 0
										? "mt-20  lg:mr-36"
										: "mt-0"
								}`}
							>
								<img src={item1?.photo?.url} alt={item1.name} />
								<div
									className={`${
										colors[(index1 + index * 2) % 3]
									} minus-margin inline-block p-4`}
								>
									<div className="text-3xl  lg:text-4xl">
										{item1.name}
									</div>
									<div>{item1.designation}</div>
									<div className="mt-4 w-48 md:w-full md:max-w-sm">
										{item1.description}
									</div>
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default About;
