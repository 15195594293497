import React from "react";
import { NavLink as Link } from "react-router-dom";

import "./header.css";
const SideDrawer = () => {
	return (
		<div className="w-64 bg-primary-black overflow-scroll h-full flex flex-col  items-start px-8 py-4 text-white text-sm">
			<Link
				exact={true}
				to="/"
				className={({ isActive }) =>
					`header-link mt-8 text-white ${
						isActive ? "opacity-100" : "opacity-50"
					}  hover:opacity-100 transition-all duration-200`
				}
			>
				HOME
			</Link>
			<Link
				exact={true}
				to="/work"
				className={({ isActive }) =>
					`header-link mt-8 text-white ${
						isActive ? "opacity-100" : "opacity-50"
					}  hover:opacity-100 transition-all duration-200`
				}
			>
				WORK
			</Link>
			<Link
				exact={true}
				to="/about"
				className={({ isActive }) =>
					`header-link mt-8 text-white ${
						isActive ? "opacity-100" : "opacity-50"
					}  hover:opacity-100 transition-all duration-200`
				}
			>
				ABOUT
			</Link>
			<Link
				exact={true}
				to="/clients"
				className={({ isActive }) =>
					`header-link mt-8 text-white ${
						isActive ? "opacity-100" : "opacity-50"
					}  hover:opacity-100 transition-all duration-200`
				}
			>
				CLIENTS
			</Link>
			<a
				href="#contact"
				className={`header-link mt-8 text-white opacity-50
							 hover:opacity-100 transition-all duration-200`}
			>
				CONTACT
			</a>
		</div>
	);
};

export default SideDrawer;
