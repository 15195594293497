import gsap, { Power1 } from "gsap";
import { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";
import elipse1 from "../../assets/elipse1.png";
import "./StatsSection.css";
const StatsSection = () => {
	const sectionRef = useRef(null);
	let ref1 = useRef(null);
	let ref2 = useRef(null);
	let ref3 = useRef(null);
	const [numberOfTimes, setNumberOfTimes] = useState(0);
	const intersection = useIntersection(sectionRef, {
		root: null,
		rootMargin: "0px",
		threshold: 0.5,
	});

	useEffect(() => {
		const textAnimation = () => {
			setNumberOfTimes(numberOfTimes + 1);
			gsap.from([ref1, ref2, ref3], {
				textContent: 0,
				duration: 2,
				ease: Power1.easeIn,
				snap: { textContent: 1 },
				stagger: 0.1,
			});
		};
		intersection &&
			intersection.intersectionRatio > 0.5 &&
			numberOfTimes < 1 &&
			textAnimation();
	}, [intersection, numberOfTimes]);
	return (
		<div className="relative">
			<div className="absolute elipse-container">
				<img
					src={elipse1}
					alt="elipse1"
					className="elipse1 w-3/4 lg:w-full"
				/>
			</div>

			<div
				ref={sectionRef}
				className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-16 lg:text-8xl text-5xl font-extrabold content-wrapper my-40 text-white relative"
			>
				<div>
					<span className="data" ref={el => (ref1 = el)}>
						100
					</span>
					mil+
					<div className="text-base font-normal ml-2">
						cumulative views on all platforms
					</div>
				</div>
				<div>
					<span ref={el => (ref2 = el)}>1000</span>+
					<div className="text-base font-normal ml-2">
						films produced
					</div>
				</div>
				<div>
					<span ref={el => (ref3 = el)}>100</span>+
					<div className="text-base font-normal ml-2">brands</div>
				</div>
			</div>
		</div>
	);
};

export default StatsSection;
