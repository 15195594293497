import React from "react";
import {
	AccordionHeader,
	AccordionNode,
	AccordionPanel,
	AccordionWithHeader,
} from "react-accordion-with-header";
import edit from "../../assets/offline.jpg";
import grading from "../../assets/grade.jpg";
import vfx from "../../assets/vfx.jpg";
import music from "../../assets/music.jpg";
import gsap, { Power3 } from "gsap";
const ServicesSection = () => {
	const [active, setActive] = React.useState(0);
	let ref = React.useRef(null);

	const items = [
		{
			title: "Offline and Online edit",
			content:
				"Our team of talented and experienced editors are equipped to handle edits for various formats of film, be it Commercials, Music Videos, Web Series, Documentaries etc. We strive for an optimal combination of high-quality products and speedy turnarounds by creating a custom approach for each job.",
			image: edit,
		},
		{
			title: "Grading",
			content:
				"Using state-of-the-art tools and methods we can bring out the best in your footage. Whether you want a subtle tweak or a dramatic makeover, let’s create the perfect look and feel for your project.",
			image: grading,
		},
		{
			title: "VFX",
			content:
				"We provide VFX and 3D computer animation solutions for films, and broadcast, by offering a dynamic range of VFX services including modeling, texturing, lighting, 2D & 3D rendering, 2D/3D animation etc. And can manage this due to the good partnership that we have made with talented artists across the industry.",
			image: vfx,
		},
		{
			title: "Music composition",
			content:
				"Whether you need a catchy theme song, a background score, a realistic ambience or a strong voice-over, our team of artists and music producers can craft, record and produce your vision, creating an immersive and memorable experience for your viewers.",
			image: music,
		},
	];

	const fadeOut = index => {
		// gsap animation to fade out the content
		gsap.fromTo(
			ref,
			{ opacity: 1 },
			{
				duration: 2,
				opacity: 0,
				ease: Power3.easeOut,
			}
		);
	};
	const fadeIn = index => {
		// gsap animation to fade in the content
		gsap.fromTo(
			ref,
			{ opacity: 0 },
			{
				duration: 2,
				opacity: 1,
				ease: Power3.easeOut,
			}
		);
	};

	const actionCallback = (panels, state) => {
		fadeOut(active);
		setActive(state.active[0]);
		fadeIn(state.active[0]);
	};
	return (
		<div className="content-wrapper text-white">
			<div className="grid grid-cols-12 mt-40 gap-4">
				<div
					className={`lg:col-span-4  col-span-12 flex flex-col justify-between order-last lg:order-1`}
				>
					<div>
						<div className="text-3xl lg:text-4xl">Our services</div>
						<div className="mt-8">
							Whether you require complete post production
							solutions for your films or just Colour Grading, VFX
							or maybe just an offline edit, don't worry we got
							you covered. Depending on whether your team requires
							to be physically present or prefers to work
							remotely, We got you covered, we have the capability
							to allow you to preview all your content in real
							time no matter what part of the world you're in. We
							understand the nightmare of having to coordinate
							with multiple team members, we have In - house Post
							Producers that have you covered.
						</div>
					</div>
					<div className="mt-10">
						<AccordionWithHeader
							firstOpen
							actionCallback={actionCallback}
						>
							{items.map((item, index) => (
								<AccordionNode
									key={index}
									className={`shadow-none rounded-none  border-b border-disabled ${
										index === active
											? "border-x border-t border-white"
											: ""
									} ${
										index === 0
											? "border-t border-disabled"
											: ""
									}`}
								>
									<AccordionHeader
										horizontalAlignment="left"
										className=" px-8 pt-7"
										style={{
											borderRadius: 0,
											boxShadow: "none",
										}}
									>
										<div
											className={`font-semibold text-xl	${
												index === active
													? "text-white"
													: "text-disabled"
											}`}
										>
											{item.title}
										</div>
									</AccordionHeader>
									<AccordionPanel
										className={`mx-8 ${
											index === active ? "my-7" : "mb-7"
										}`}
									>
										<div>{item.content}</div>
									</AccordionPanel>
								</AccordionNode>
							))}
						</AccordionWithHeader>
					</div>
				</div>
				<div className="lg:col-span-7 lg:col-start-6 col-span-12 order-1 lg:order-last">
					<img
						src={items[active]?.image || edit}
						alt={items[active]?.title}
						ref={el => (ref = el)}
						className="w-full"
					/>
				</div>
			</div>
		</div>
	);
};

export default ServicesSection;
