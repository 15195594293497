import ClientsImg from "../assets/clients.png";
import SEO from "../components/SEO";

const Clients = () => {
	return (
		<div className="pt-40 text-white">
			<SEO title="Clients | Postbox 28" />
			<div className="text-3xl md:text-4xl content-wrapper  lg:text-6xl lg:leading-normal text-center">
				Our satisfied customers
			</div>
			<div className="w-full flex oval align-middle justify-center mt-24">
				<img
					src={ClientsImg}
					alt="Clients"
					className="z-10 my-40 lg:px-16 px-6"
				/>
			</div>
		</div>
	);
};

export default Clients;
