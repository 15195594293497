import gsap from "gsap";
import React, { useEffect, useState } from "react";
import {
	AccordionHeader,
	AccordionNode,
	AccordionPanel,
	AccordionWithHeader,
} from "react-accordion-with-header";
import styled from "styled-components";
import SEO from "../components/SEO";

const StyledAccordionHeader = styled(AccordionHeader)`
	&:before {
		content: " ";
		opacity: 0;
		content: " ";
		position: absolute;
		transition: opacity 0.2s ease-in-out;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(${props => props.url});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	&:hover {
		&:before {
			opacity: 0.5;
		}
	}
`;

const Work = () => {
	const [data, setData] = useState({});
	const [active, setActive] = useState(-1);
	const actionCallback = (panels, state) => {
		setActive(state.active[0]);
	};

	useEffect(() => {
		const query = `{
      postbox28ProjectsCollection {
          items{
            name
            client
						type
						role
						thumbnail {
							url
						} 
            description
            url
            addtionalFields
          }
      }
    }`;
		const fetchAPI = async () => {
			try {
				const response = await fetch(
					"https://graphql.contentful.com/content/v1/spaces/j09fj8t8jcuw/",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization:
								"Bearer kkenpDD079ww3vagsbAq9X7d9X5gTEmYGXcsDTtpP6k",
						},
						body: JSON.stringify({
							query: query,
						}),
					}
				);
				const data = await response.json();
				setData(data);
			} catch (err) {
				console.log(err);
			}
		};
		fetchAPI();
	}, []);

	return (
		<div className="content-wrapper text-white">
			<SEO title="Our work | Postbox 28" />
			<div className="text-4xl lg:text-6xl pt-40 lg:leading-relaxed	 max-w-screen-md">
				Browse our list of successful projects
			</div>
			<div className="grid grid-cols-12 gap-4 mt-12 border-b border-disabled pb-8">
				<div className="lg:col-span-4 font-semibold col-span-12 lg:ml-4 text-disabled">
					PROJECT
				</div>
				<div className="lg:col-span-2 font-semibold hidden lg:block text-disabled">
					CLIENT
				</div>
				<div className="lg:col-span-2 font-semibold hidden lg:block text-disabled">
					TYPE
				</div>
				<div className="lg:col-span-2 font-semibold hidden lg:block text-disabled">
					OUR ROLE
				</div>
			</div>
			{data.data?.postbox28ProjectsCollection?.items && (
				<AccordionWithHeader actionCallback={actionCallback}>
					{data.data?.postbox28ProjectsCollection?.items.map(
						(item, index) => (
							<AccordionNode
								key={index}
								className={`shadow-none rounded-none border-b border-disabled  `}
							>
								<StyledAccordionHeader
									url={item.thumbnail.url}
									horizontalAlignment="left"
									className="relative acc-header"
								>
									<div className="grid grid-cols-12 py-7  gap-4 text-white w-full">
										<div className="lg:col-span-4 font-semibold col-span-12 lg:ml-4">
											{item.name}
										</div>
										<div className="lg:col-span-2  hidden lg:block">
											{item.client}
										</div>
										<div className="lg:col-span-2 hidden lg:block">
											{item.type}
										</div>
										<div className="lg:col-span-2 hidden lg:block">
											{item.role}
										</div>
									</div>
									<div
										className={`absolute lg:right-6 right-2 transition-all ${
											active === index ? "rotate-180" : ""
										}`}
									>
										<svg
											width="15"
											height="21"
											viewBox="0 0 15 21"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0.199135 12.5238L7.00568 20.5708C7.06721 20.6436 7.14328 20.702 7.22872 20.742C7.31417 20.782 7.40701 20.8027 7.50095 20.8027C7.59488 20.8027 7.68772 20.782 7.77317 20.742C7.85862 20.702 7.93468 20.6436 7.99621 20.5708L14.8007 12.5238C14.8213 12.4995 14.8346 12.4697 14.8391 12.4379C14.8436 12.4061 14.8391 12.3736 14.8262 12.3443C14.8132 12.3151 14.7923 12.2903 14.766 12.2728C14.7397 12.2554 14.709 12.2461 14.6777 12.2461H13.0165C12.9222 12.2461 12.832 12.2881 12.7684 12.3618L8.27922 17.6696V0.768265C8.27922 0.675697 8.20539 0.59996 8.11516 0.59996H6.88469C6.79445 0.59996 6.72062 0.675697 6.72062 0.768265L6.72062 17.6696L2.23146 12.3618C2.16994 12.2881 2.0797 12.2461 1.98332 12.2461L0.322182 12.2461C0.182729 12.2461 0.10685 12.4144 0.199135 12.5238Z"
												fill="#E3E3E3"
											/>
										</svg>
									</div>
								</StyledAccordionHeader>

								<AccordionPanel
									key={index}
									className="grid grid-cols-12 gap-4 h-full text-white w-full"
								>
									<div className="lg:col-span-8 col-span-12  h-full lg:col-start-5 mb-8">
										<div className="grid grid-cols-12 gap-4">
											<div className="lg:col-span-6 col-span-12">
												<img
													className="w-full"
													src={item.thumbnail.url}
													alt={item.name}
												/>
											</div>
											<div className="lg:col-span-6 col-span-12 lg:border-t border-disabled flex flex-col justify-between h-full mt-2">
												<div className="mt-8 max-w-lg">
													{item.description}
												</div>
												<a
													href={item.url}
													target="_blank"
													rel="noopener noreferrer"
													className="flex flex-row items-center text-teal mb-2"
												>
													<div className="mr-2">
														Watch the full video
														here
													</div>
													<svg
														width="15"
														height="10"
														viewBox="0 0 15 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M9.05502 9.8672L14.626 5.3295C14.6764 5.28849 14.7169 5.23778 14.7446 5.18081C14.7723 5.12384 14.7866 5.06195 14.7866 4.99933C14.7866 4.93671 14.7723 4.87481 14.7446 4.81785C14.7169 4.76088 14.6764 4.71017 14.626 4.66915L9.05502 0.132822C9.03823 0.119114 9.01759 0.110225 8.99557 0.107216C8.97355 0.104206 8.95107 0.107202 8.93081 0.115846C8.91055 0.12449 8.89337 0.138418 8.8813 0.155972C8.86924 0.173525 8.8628 0.193964 8.86276 0.214853L8.86276 1.32228C8.86276 1.38517 8.89189 1.44532 8.94287 1.48771L12.6175 4.48048L0.916601 4.48048C0.852516 4.48048 0.800083 4.5297 0.800083 4.58985L0.800083 5.41017C0.800083 5.47032 0.852516 5.51954 0.916601 5.51954L12.6175 5.51954L8.94287 8.51232C8.89189 8.55333 8.86276 8.61349 8.86276 8.67775L8.86276 9.78517C8.86276 9.87814 8.97928 9.92873 9.05502 9.8672Z"
															fill="#1EBBB9"
														/>
													</svg>
												</a>
											</div>
										</div>
									</div>
								</AccordionPanel>
							</AccordionNode>
						)
					)}
				</AccordionWithHeader>
			)}
		</div>
	);
};

export default Work;
