import AboutUs2 from "../../assets/AboutUs2.png";
import AboutUs1 from "../../assets/AboutUs1.jpg";

const AboutUsSection = () => {
	return (
		<div className="content-wrapper mt-40 text-white">
			<div className="text-3xl lg:text-4xl">About us</div>
			<div className="mt-8 grid grid-cols-12 gap-4">
				<div className="col-span-12 lg:col-span-8 flex flex-col justify-between">
					<div className="text-base my-4 text-white max-w-xl">
						Postbox 28 is a Post-Production house based out of
						Mumbai. We excel in the art of effective storytelling.
						We offer complete Post-Production Solutions which
						include offline editing, Colour Grading, VFX, Animation,
						Music Composition and everything in between. We have
						managed to amass more than a 100 million views on the
						videos that we have worked on.
					</div>

					<img
						src={AboutUs1}
						alt="about us 1"
						className="mt-4 lg:mt-0"
					/>
				</div>
				<div className="col-span-12 lg:col-span-4 flex align-bottom">
					<img src={AboutUs2} alt="about us 2" />
				</div>
			</div>
		</div>
	);
};

export default AboutUsSection;
