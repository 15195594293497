import { Drawer } from "@mui/material";
import { useState } from "react";
import { Navigate, Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import SideDrawer from "./components/Header/SideDrawer";
import About from "./screens/About";
import Clients from "./screens/Clients";
import Home from "./screens/Home";
import Work from "./screens/Work";
import ScrollToTop from "./scrollToTop";

function App() {
	const [open, setOpen] = useState(false);

	const openDrawer = () => {
		setOpen(true);
	};
	return (
		<div className="bg-primary-black min-h-screen">
			<BrowserRouter>
				<ScrollToTop />
				<Header openDrawer={openDrawer} />
				<main>
					<Routes>
						<Route path="/" element={<Home />} exact />
						<Route path="/work" element={<Work />} exact />
						<Route path="/about" element={<About />} exact />
						<Route path="/clients" element={<Clients />} exact />
						<Route path="*" element={<Navigate replace to="/" />} />
					</Routes>
				</main>
				<Footer />
				<Drawer
					anchor="right"
					open={open}
					onClose={() => setOpen(false)}
				>
					<SideDrawer />
				</Drawer>
			</BrowserRouter>
		</div>
	);
}

export default App;
