import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import favicon from "../assets/favicon.ico";
const SEO = ({ description, keywords, title, author }) => {
	const { pathname } = useLocation();
	const metaDescription = description;
	const metaTitle = title;
	const metaKeywords = keywords || [];
	const metaAuthor = author;
	const metaUrl = `https://www.postbox28.in/${pathname}`;
	return (
		<Helmet
			htmlAttributes={{ lang: "en" }}
			title={metaTitle}
			link={[{ rel: "icon", href: `${favicon}` }]}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: metaAuthor,
				},
				{
					name: `twitter:title`,
					content: metaTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: "twitter:card",
					content: "summary_large_image",
				},
				{
					name: "og:url",
					content: metaUrl,
				},
			].concat({ name: "keywords", content: metaKeywords.join(`, `) })}
		/>
	);
};

export default SEO;
